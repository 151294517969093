.esc-slider{
  position: relative;
  width: 100%;
  display: flex;
  background-color: black;
}
.esc-slider-container{
background: white;
padding: 0px;
position: fixed;
box-shadow: 0px 0px 6px 0px #00000033;
z-index: 999;
display: block;
overflow: hidden;
}
.sliderLeftBox{
width: 223px;
overflow: auto;
border-right: 1px solid #0000001f;

}

.sliderRightBox{
    flex: 2;
    overflow: auto;
    padding: 20px;
}
.closeBtn {
  display: block;
  position: absolute;
  right: 10px;
  top: 1px;
  padding: 10px;
  cursor: pointer;
  z-index: 2;
}
.right-0{
  right: 0px;

}
.top-0{
  top: 0px;
}
.top-right{
  top: 0px;
  right: 0px;
}
.bottom-right{
  bottom: 0px;
  right: 0px;
}
.left-0{
  left: 0px;

}
.bottom-0{
  bottom: 0px;
}
.sliderHeader{
  border-bottom: 1px solid #ddd;
  width: 100%;
  height: 48px;
  padding: 10px;
  background-color: #ffffff !important;
  color: #111;
  display: grid;
  align-items: center;
  text-align: center;
}
.slider-container{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.sliderFooter{
  width: 100%;
  padding: 10px;
  color: black;
  text-align: right;
  padding: 9px;
  background: #F4F4F4 0% 0% no-repeat padding-box;
  border-top: 1px solid #dcdcdc;
}
.sliderBody{
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  background-color: #F5F5F5;
}

.sliderContainer{
width: 100%;
height: 100%;
position: fixed;
top: 0px;
left: 0px;
background-color: #00000061;
transition: 500ms ease-in;
z-index: 999;
}
.sliderOut{
transition: 500ms ease-out;
width: 100%;
height: 100%;
}
.noShadow{
width: 100%;
height: 100%;
position: fixed;
top: 0px;
left: 0px;
transition: 500ms ease-in;
}
.small{
width: 320px;
height: 100%;
}
.medium{
top: 0px;
width: 50%;
height: 100%;
}
.large{
width: 70%;
height: 100%;
top: 0px;
}
.x-large {
width: 80%;
height: 100%;
}
.semi-medium{
top: 0px;
width: 382px;
height: 100%;
}
.sliderHeader{padding:10px 15px;}
.sliderHeader .modal-title{font-size:16px;}