.header {
    padding: 10px;
    background-color: #4596b7;
}
.header a{
       display: inline-block;
        margin-right: 10px;
        padding: 5px 10px;
        color: wheat;
}
.header a svg{
    width: 18px;
    margin-right: 5px;
}


.nav_right {
    float: right;
}
.modal {
    background: rgba(0, 0, 0, 0.6);
  }
  .modal-container {
    position: absolute;
  top: 50%;
    background: #ffffff;

   
   
    padding: 20px;
  }
