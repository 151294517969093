.trn-main-title{
    border-left: 3px solid #000000;
    height: 30px;
}
.trn-action{
    gap: 20px;
    margin-top: 40px;
}
.trn-action-lbl{
    color: #5CA017;
    font-size: 12px;
}
.trn-bt-label{
    height: 24px;
    width: 68px;
    color: #084D50;
    background-color: #B8F7FA;
    border-radius: 12px;
    font-size: 12px;
    text-align: center;
}
.trn-bt-label-sell{
    background-color: #DEB8FA !important;
    color: #330E4E !important;
}
.trn-bt-label-expired{
    background-color: #F87171 !important;
    color: #ffffff !important;
}

.trn-item-h-title{
    color: #2E35FF;
    font-size: 13px;
}
.trn-item-price{
    font: normal normal bold 16px/19px Gill Sans;
    color: #487916;
}
.trn-item-desc{
    font: italic normal normal 12px/16px Gill Sans;
}
.trn-item-img {
    width: 56px;
    height: 56px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #eee;
}
.trn-item-details{
    line-height: 1.4;
}
.trn-item-timeline{
    border: 1px solid #8DB761;
    border-radius: 3px;
    height: 25px;
    cursor: pointer;
}
.trn-item-timeline-arrow {
    color: #8DB761;
}
.trn-item-exp{
    color: #E32929;
}
.trn-item-bid-status{
    /* gap: 10px; */
}
.trn-item-trade-box{
    height: 132px;
    width: 160px;
    padding: 5px 10px;
    border: 1px solid #E6E6E6;
    border-radius: 3px;
}
.trn-trade-circle {
    width: 40px;
    height: 40px;
    border: 1px solid #477716;
    border-radius: 50%;
    box-shadow: 0px 3px 3px #00000029;
    position: absolute;
    top: 25px;
    right: -23px;
    background-color: white;
    display: grid;
    z-index: 998;
    align-items: center;
    justify-content: center;
  }
  .timeline-circle-outer{
      height: 16px;
      width: 16px;
      border: 1px solid #ACE8FF;
      border-radius: 50%;
      background: #ACE8FF 0% 0% no-repeat padding-box;
      z-index: 2;
  }
  .timeline-circle-inner{
    height: 8px;
    width: 8px;
    border: 1px solid #ACE8FF;
    border-radius: 50%;
    background: #16ADE5 0% 0% no-repeat padding-box;
}
.timeline-vertical-line{
    height: 80%;
    border-left: 2px solid #EBEBEB;
    left: 7px;
    top: 5px;
    position: absolute;
    z-index: 0;
}
.trn-view-active-offer{
    height: 30px;
    width: 100%;
    background-color: #FFF395;
    color: #E83106;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
}
.trn-vao-icon{
    width: 10px
}
.trn-vao-list-item{
    min-height: 32px;
    cursor: pointer;
}
.trn-search-icn-close{
    position: absolute;
    top: 22px;
    right: 85px;
    z-index: 5;
    cursor: pointer;
    transition: all 0.2s;
}
.trn-search-inp input{
    padding: 5px 35px 5px 10px !important;
}
.trn-action .dropdown-custom, .trn-action-filter-by{
    width: 162px;
    height: 28px;
}
.trn-action .trn-action-filter-by{
    width: 162px;
    height: 28px;
}