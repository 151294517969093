.addListingItem{
    border: 1px solid rgb(226 226 226 / 36%);
    box-shadow: 0px 0px 3px 0px rgb(133 133 133 / 36%);
    padding: 5px 30px;
    border-radius: 2px;
    color: black;
    cursor: pointer;
    margin: 15px 0px;
}
.creating-listing-modal{
    width: 470px !important;
}
.listing-item-body {
    height: 70vh;
    overflow: auto;
    padding: 15px;
}
.list-item-s{
    box-shadow: 0px 0px 5px 0px #0000003b;
    padding: 20px 15px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
    position: relative;
    overflow: hidden;
}
.listing-item-tab .nav-pills .nav-link.active {
    color: #111;
    border-bottom: 3px solid #5BA018;
    background: #fff;
    font-weight: 600;
}
.listing-item-tab .nav-pills .nav-link.active {
    color: #5BA018;
    border-bottom: 3px solid #5BA018;
    background: #fff;
    font-weight: 600;
  }
  .listing-item-tab .nav-pills .nav-link {
    border-radius: 0;
  }
  .listing-item-tab .nav-link {
    display: block;
    padding: 10px 1rem;
    font-size: 14px;
  }
  .listing-item-tab .nav-pills .nav-link {
    border-radius: 0;
  }
  .listing-item-tab .nav-pills a {
    color: #676767;
    text-decoration: none;
    background-color: transparent;
  }
  .listing-item-tab .nav-pills {
    border-bottom: 1px solid #dcdcdc;
  }
  .primary-tag{
    position: absolute;
    top: -4px;
    left: -4px;
    background: #333333;
    color: white;
    padding: 0px 10px;
    font-size: 9px;
    border-radius: 6px;
    height: 20px;
    align-items: center;
  }
  .retunable-inp{
    max-width: 75px;
    margin-right: 5px;
  }
  .return-period-err{
    position: absolute !important;
    top: 35px !important;
    left: -6px !important;
    width: 200px !important;
  }
  .return-percentage-err{
    position: absolute;
    top: 40px;
    left: 95px;
  }
  .return-dollal-err{
    position: absolute;
    top: 40px;
    left: 234px;
  }
  .form-check label{
    cursor: pointer;
  }
  .create-listing label{
    margin-bottom: 0px;
  }
  .create-listing .form-control{
    height: 38px;
    display: flex;
    align-items: center;
  }
  .create-listing textarea {
    height: 100px !important;
    display: flex;
    align-items: center;
  }
  .addPrimarySecondaryListing label{
    /* padding-top: 6px !important; */
    margin-bottom: 0px;
  }
  .listing-type label{
    padding-left: 10px !important;
    padding-top: 8px !important;
  }
  .listing-type .form-check-inline{
    margin-right: 40px !important;
  }
  .dollarSign-shipping-fee{
    background-color: #e9ecef !important;
    background: url("../../assets/images/icon/currency-dollar.svg")no-repeat 6px 10px !important;
  }
  .disabledfess{
    background-color: #e9ecef !important;
  }
  .invalid-feedback{
    display: inline !important;
  }
  .in-valid{
    border-color: #dc3545 !important;
    box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%) !important;
  }