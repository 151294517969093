.myBidbox {
    background:white;
    padding: 10px 20px;
    margin: 15px 0px;
}
.myBidbox-title{
    color: #2E35FF;
    padding-bottom: 5px;
}
.small-size{
    font-size: 12px;
}
.font-it{
    font-style: italic;
    font-size: 14px;
    color:#333333;
}
