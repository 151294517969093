
.embed-responsive-item{
  background-image: url("../../../../assets/images/loader1.gif");
  background-position: center;  
  background-repeat: no-repeat;
}
.cd-signin-modal .payment-modal-iframe{
    width: 500px !important;
    background: #DFDFDF;
    border-radius: 10px;
    border: 1px solid #355f0b;
}

.xyz{
    transition: opacity 0.3s, visibility 0.3s;
    background: rgba(52, 54, 66, 0.9);
}
.iframe-modal{
    /* padding: 10px; */
    min-height: 585px;
    background: #fff;
    border-radius: 10px;
    /* background-color: #355f0b; */
}
.pmt-iframe{
    border: 1px solid #ffffff !important;
}
.iframe-header{
    height: 64px;
    background: #355f0b !important;
    /* display: flex; */
    /* justify-content: center; */
    align-items: center;
    color: #ffffff;
    /* font-weight: bold; */
    font-size: 24px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    top: 0;
    position: relative;
    letter-spacing: 1px;
}
.iframe-bottom{
    height: 46px;
    background: #355f0b !important;
    /* display: flex; */
    /* justify-content: center; */
    align-items: center;
    color: #ffffff;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    position: relative;
    bottom: 0;
}


.loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #355f0b;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
