.forbidden-page{
    height: 100vh;
    width: 100%;
    background-color: #e9e9e9;
    color: #000;
    text-align: center;
 
}
.forbidden-page-text{
    width: 450px;
    height: 200px;
    line-height: 1.1;
    font-size: 24px;
}