.uncleared-form{
    display: flex;
    display: flex;
    align-items: center;
    gap: 5px;
}
.f14{
    font-size: 14px;
}
.f16{
    font-size: 16px;
}
.f18{
    font-size: 18px;
}
.f20{
    font-size: 20px;
}
.fwb{
    font-weight: bold;
}
.C111{
    color: #111111 !important;
}
.min-width-250{
    min-width: 250px;
}
.p4{
    padding: 4px !important;
}
.p5{
    padding: 6px !important;
}
.px5{
    padding-left: 5px;
    padding-right: 5px;
}
.py5{
    padding-top: 5px;
    padding-bottom: 5px;
}
.px10{
    padding-left: 10px;
    padding-right: 10px;
}
.py10{
    padding-top: 10px;
    padding-bottom: 10px;
}
.pt10{
    padding-top: 10px;
}
.pl10{
    padding-left: 10px;
}
.uncleared-payment{
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}
.transaction-table{
    padding-top: 10px;
}
.view-more{
    max-width: 150px;
    border: 1px solid #ddd;
    padding: 5px;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    background: #c7b8f5;
    color: #FFFFFF;
    cursor: pointer;
}
.history-table{
    padding: 10px;
    max-height: 350px;
    overflow: auto;
}
.txn-settings{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 15px 10px -15px #ddd;
}
.txt-settings-left{
    display: flex;
    gap: 10px;
    align-items: center;
}
.mb0{
    margin-bottom: 0px !important;
}
.pd0{
    padding: 0px !important;
}
.report-btn{
    border: 1px solid #5bc0de;
    border-radius: 5px;
    background: #5bc0de;
}
.custom-date{
    display: flex;
    gap: 5px;
    align-items: center;
}
.fc-pd3{
    padding: .375rem .75rem;
}
.tb-status{
    border-radius: 12px;
    display: flex;
    align-items: center;
    max-width: 120px;
}
.pmt-success{
    border: 1px solid #3a833a;
    color: #3a833a;

}
.pmt-failed{
    border: 1px solid #a72925;
    color: #a72925;

}
.btn-pd{
    padding: 9px 30px !important;
}
.uncleared-payment-1{
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    /* border: 1px solid #ddd; */
    border-radius: 10px;
    background: #efefef;
    box-shadow: 0px 15px 10px -15px #ddd;
}
.history-link{
    color: #4286f4;
    text-decoration: underline;
}
.tb-status-icon{
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Transaction details screen */

.txn-header{
    display: flex;
    align-items: center;
    gap: 12px;
    height: 66px;
    border-bottom: 1px solid #ddd;
    width: 100%;
    background-color: #3a833a;
    color: #FFFFFF;
    /* padding: 10px 10px; */
}
.txn-status{
    display: flex;
    flex-direction: column;
}
.txn-status-msg{
    font-size: 20px;
}
.txn-body{
    overflow: auto;
}
.txn-settled{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #DFDFDF;
    border-radius: 10px;
}
.card-with-icon{
    display: flex;
    align-items: center;
    gap: 10px;
}
.txn-card{
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: space-between;
}
.txn-footer{
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    background: #FFFFFF;
    border-top: 1px solid #ddd;
    
}
.txn-card-icon{
    background: #DFDFDF;
    width: 40px;
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    color: #FFFFFF;
    font-weight: bold;
}

.order-img{
    width: 110px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #ddd;
}

.settings-right:hover{
    border-radius: 50%;
    background: #dfdfdf;
}
